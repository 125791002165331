import React from 'react';
import SignIn from '../page/signIn';
import LandingLayout from '../landing/landingLayout';
import Register from '../page/Register';
import LandingPage from '../page/landing-page'
import DashboardLayout from '../dashboard/DashboardLayout';
import AppList from '../page/AppList'
import Appform from '../page/Appform'
import Lowongan from '../page/Lowongan'
import Profil from '../page/profil';
import {AppProvider} from'./AppContext'
import LowonganDetail from'../page/lowonganDetail'
import SearchSection from '../page/SearchSection'
import FilterSection from '../page/FilterSection'
import Cookies from "js-cookie";
import {
    BrowserRouter as Router,
    Switch,
    Route,} from 'react-router-dom'

const Routes = () => {
    return (
    <>  
       <Router>
       <AppProvider>
        
       <Switch>
       <Route path="/Appform" exact>
              
              <Appform />
              
              </Route>
              <Route path="/Appform/edit/:id" exact>
             
             <Appform />
    
              </Route>
         <Route path="/" exact>
            
             <LandingLayout>
                  <LandingPage />
            </LandingLayout>
              </Route>

              <Route path="/Filter" exact>
            
             <LandingLayout>
                  <FilterSection />
            </LandingLayout>
              </Route>

              <Route path="/Login" exact>
               <LandingLayout>
                  <SignIn />
             </LandingLayout>
              </Route>
              <Route path="/Register" exact>
               <LandingLayout>
                  <Register />
             </LandingLayout>
              </Route>
               <Route path="/Lowongan" exact>
               <LandingLayout>
                   
                  <Lowongan />
                  </LandingLayout>
              </Route>
              <Route path="/LowonganDetail/:slug" exact>
                
                  <LowonganDetail />
    
              </Route>
            
              <Route path="/Lowongan/:ValueSearch" exact>
               <LandingLayout>
                  <SearchSection />
                  </LandingLayout>
              </Route>

               <Route path="/AppList" exact>
               
              <DashboardLayout>
              <AppList />
              </DashboardLayout>
              </Route>
              
             <Route path="/Profil" exact>
             
            <DashboardLayout>
                 <Profil />
           </DashboardLayout>
             </Route>
             
        </Switch>
        
        </AppProvider>
       </Router>
    </>
    )}
    export default Routes;