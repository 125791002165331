import React, { useState, createContext,useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom"
import Cookies from "js-cookie";

export const AppContext = createContext();


export const AppProvider = props => {
  const [newdata,setNewdata]= useState([])
  let name = Cookies.get('name')
  let email= Cookies.get('email')
  let image_url= Cookies.get('image_url')
  let token= Cookies.get('token') 
  const [pass,setPass]= useState({
    current_password:"",
    new_confirm_password:"",
    new_password:""
  })
  const [search,setSearch]= useState()
  const thClass =
  "px-4 py-4 text-left bg-blue-900 text-white text-sm font-medium"
const tdClass = "px-4 py-8 text-left border-t border-b border-gray-300 text-sm"
const trClass = "border-gray-300"

const [filterdata,setFilterdata]= useState({
  title:"",
  salary_min:0,
  company_city:""
})
const [inputLogin, setInputLogin] = useState({
  name:"",
  image_url:"",
    email : "",
    password : ""
})


    const[workList,setWorkList]= useState([]   
        )
    const history=useHistory()
    const [status, setStatus]= useState(true)
    const [currentId, setCurrentId]=  useState(-1)
    
    const [input,setInput]=useState({
        id: 0,
        title:"",
        job_description:"",
        job_qualification :"",
        job_type:"" ,
        job_tenure:"" ,
        job_status:0,
        company_name:"" ,
        company_image_url:"" ,
        company_city:"" ,
        salary_min:0,
        salary_max:0  
    },
    {headers: {"Authorization" : "Bearer "+ token}}
    )

    const handleSelect = (event) =>{
     let id = parseInt(event.target.value)
     history.push(`/LowonganDetail/${id}`)

          }
         
          useEffect( () => {
            const fetchData = async () => {
            const { data:{data} } = await axios.get(`https://dev-example.sanbercloud.com/api/job-vacancy`)
          
          
let result = data.map((res)=>{ 
          
  let{id,
    title,
    job_description,
    job_qualification ,
    job_type ,
    job_tenure ,
    job_status,
    company_name ,
    company_image_url ,
    company_city ,
    salary_min,
    salary_max 
  }= res 
          
  return{id,
    title,
    job_description,
    job_qualification ,
    job_type ,
    job_tenure ,
    job_status,
    company_name ,
    company_image_url ,
    company_city ,
    salary_min,
    salary_max 
  } 
}, )
          
setWorkList([...result])
              }
if(status){
          
            fetchData()
            setStatus(false) }

              
            }, [status,setStatus])


             const BackLowongan=(event)=>{
              setStatus(true)
              if(status == true){ 
              history.push('/Lowongan')}
              else{
                history.push('/')
              }
               
               
             }

            const handleFilter = (event) => {
              event.preventDefault()
            

            
            axios.get(`https://dev-example.sanbercloud.com/api/job-vacancy`,)
              .then(({data}) => {
              

                  let results =data.data
                  let result = results.filter((res)=>{
                    return  res.company_city.toLowerCase() === filterdata.company_city.toLowerCase() 
                  })

            history.push("/Filter")
                  setNewdata(result)
            })
            }


  const handleRegister = (event) => {
    event.preventDefault()
  
    let {name,image_url,email, password} = inputLogin
  
  axios.post(`https://dev-example.sanbercloud.com/api/register`, {name,image_url,email, password})
    .then((res) => {
       
        let {token} = res.data
        history.push('/Login')
        
     
  })
  }
   const handleLogin = (event) => {
          event.preventDefault()
  
    let {email, password} = inputLogin
  
    axios.post(`https://dev-example.sanbercloud.com/api/login`, {email, password})
          .then((res) => {
            
              let {token} = res.data
              let {email,name,image_url} = res.data.user
              Cookies.set('token', token)
              Cookies.set('email', email)
              Cookies.set('name', name)
              Cookies.set('image_url', image_url)
              if(email !== undefined){
              history.push("/Applist")}
              else{
                history.push("/")
              }
             
              setStatus(true)
              
    },[status, setStatus])
  }
  const handleSubmit = (event) =>{
    event.preventDefault()
  
    let {title,
      job_description,
      job_qualification ,
      job_type ,
      job_tenure ,
      job_status,
      company_name ,
      company_image_url ,
      company_city ,
      salary_min,
      salary_max } =input
  
    if(currentId === -1){
      axios.post(`https://dev-example.sanbercloud.com/api/job-vacancy`, 
      {title,
        job_description,
        job_qualification ,
        job_type ,
        job_tenure ,
        job_status,
        company_name ,
        company_image_url ,
        company_city ,
        salary_min,
        salary_max },
        {headers: {"Authorization" : "Bearer "+ token}})
      .then((res)=>{
          history.push("/Applist")
          setStatus(true)
          setInput({ 
            title:"",
            job_description:"",
            job_qualification :"",
            job_type:"" ,
            job_tenure:"" ,
            job_status:0,
            company_name:"" ,
            company_image_url:"" ,
            company_city:"" ,
            salary_min:0,
            salary_max:0 
  }
  )
      })
      setStatus(true)
    }else{
      
      axios.put(`https://dev-example.sanbercloud.com/api/job-vacancy/${currentId}`,{title,
      job_description,
      job_qualification ,
      job_type ,
      job_tenure ,
      job_status,
      company_name ,
      company_image_url ,
      company_city ,
      salary_min,
      salary_max},
      {headers: {"Authorization" : "Bearer "+ token}})
  .then((res)=>{
    history.push("/AppList")
  setInput({    title:"",
  job_description:"",
  job_qualification :"",
  job_type:"" ,
  job_tenure:"" ,
  job_status:0,
  company_name:"" ,
  company_image_url:"" ,
  company_city:"" ,
  salary_min:0,
  salary_max:0})     
  setCurrentId(-1)
  setStatus(true)
  })
      
    }
  }

  const handleChange = (event) =>{
    let name = event.target.name
    let value = event.target.value
                  
    setInputLogin({...inputLogin, [name]:value})
    }
    const back=(event)=>{
      history.push('/Lowongan')
      setFilterdata({
        job_status:0,
        salary_min:0,
        company_city:""
      })
    }
    const handleChangeSearch = (event) =>{
     
      let value = event.target.value

      setSearch(value)
      
      }
      const handleSearch = (event) =>{
        
        setSearch("")
        history.push(`/Lowongan/${search}`)
      }

      const handleChangeFilter = (event) =>{
        let name = event.target.name
        let value = event.target.value
                      
        setFilterdata({...filterdata, [name]:value})
        }
    const handleChangeform = (event) =>{
      let name = event.target.name
      let value = event.target.value
                    
      setInput({...input, [name]:value})
      }
      const handleChangePass = (event) =>{
        let name = event.target.name
        let value = event.target.value
                      
        setPass({...pass, [name]:value})
        }

      const ChangePassword = (event) => {
        event.preventDefault()

  let {current_password, new_password,new_confirm_password} = pass

  axios.post(`https://dev-example.sanbercloud.com/api/change-password`,
  {current_password, new_password,new_confirm_password},
    {headers: {"Authorization" : "Bearer "+ token}})
  .then((res)=>
  {
  setPass({  current_password:"",
    new_confirm_password:"",
    new_password:""
  }) 
history.push("/AppList") })

          
  }

      const handleDelete = (event) =>{
        let id = parseInt(event.target.value)
        axios.delete(`https://dev-example.sanbercloud.com/api/job-vacancy/${id}`, {headers: {"Authorization" : "Bearer "+ token}})
        .then(() => {
        let newWorkList = workList.filter(el=> {return el.id !== id})
              setWorkList(newWorkList)
              setStatus(true)
              console.log(newWorkList)
            })
            .catch(err => {
              console.error(err)
            })
          }
          const handleEdit = (event) =>{
            let id = parseInt(event.target.value)
           history.push(`/Appform/edit/${id}`)
            }

  const LogOut=(event)=>{
    Cookies.remove('token')
    Cookies.remove('email')
    Cookies.remove('name')
    Cookies.remove('image_url')
  history.push('/Login') 
     
     setStatus(true)
  }
  const JobType = (phrm) => {
if (phrm > 0){
  return ("Aktif")
}else {
  return ("Non Aktif")
}
  }


  
    let stage={
        workList,setWorkList,
        status, setStatus,
        currentId, setCurrentId,
        input,setInput,
        inputLogin, setInputLogin,
        thClass,tdClass,trClass,
        pass,setPass,name,email,image_url,token,search,setSearch,
        filterdata,setFilterdata,newdata,setNewdata
    }
    let handleFunction={handleSelect,handleChange,handleRegister,handleLogin,JobType,handleSubmit,handleChangeform,handleDelete,
      handleEdit,LogOut,handleChangePass,ChangePassword,
      handleChangeSearch,handleSearch,back,handleChangeFilter,handleFilter,BackLowongan }
    return (
        <AppContext.Provider value={{stage, handleFunction}}>
          {props.children}
        </AppContext.Provider>
      );
};