import { Link} from 'react-router-dom'
import Logo from '../asset/images/img-01.png'
import Text from '../asset/images/text.png'
import React,{useContext,useEffect} from 'react'
import '../asset/css/sidebar.css'


const Sidebar = () => {
  
  
      return (
          <> 

<div className="sidebar">

 <p>
       <Link to ="/AppList">Dashboard</Link>
 </p>
 <p>
       <Link to ="/Profil">Profil</Link>
 </p>
  
</div>
           

      </>)

}
export default Sidebar