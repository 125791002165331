import React,{useState,useContext} from 'react';
import Logo from '../asset/images/img-01.png'
import { Link,useHistory } from 'react-router-dom';
import axios from "axios"

import {AppContext} from "../context/AppContext"



const Register=()=>{
  const {stage,handleFunction} = useContext(AppContext)
  let {
    workList,setWorkList,
    status, setStatus, history,
    currentId, setCurrentId,
    input,setInput,
    inputLogin, setInputLogin
}=stage
  
let {handleSelect,handleChange,handleRegister,handleLogin }= handleFunction


    return (

        <div className="limiter">
          {console.log(inputLogin)}
          
          <div className="container-login100">
            <div className="wrap-login100">
              <div className="login100-pic js-tilt" data-tilt>
                <span className="login100-form-title">Welcome to Search-Work</span> 
                <img src={Logo} alt="IMG" />
              </div>
              <form onSubmit={handleRegister} method="POST" className="login100-form validate-form">
                <span className="login100-form-title font-bold">
                  Register Form
                </span>

                <div className="wrap-input100 validate-input">
                  <input onChange={handleChange} className="input100" type="text" name="name" placeholder="name" required />
                  <span className="focus-input100" />
                  <span className="symbol-input100">
                    <i className="fa fa-envelope" aria-hidden="true" />
                  </span>
                </div>

                <div className="wrap-input100 validate-input" >
                  <input onChange={handleChange} className="input100" type="text" name="image_url" placeholder="Image Url" required/>
                  <span className="focus-input100" />
                  <span className="symbol-input100">
                    <i className="fa fa-envelope" aria-hidden="true" />
                  </span>
                </div>

                <div className="wrap-input100 validate-input" required >
                  <input onChange={handleChange} className="input100" type="text" name="email" placeholder="Email" />
                  <span className="focus-input100" />
                  <span className="symbol-input100">
                    <i className="fa fa-envelope" aria-hidden="true" />
                  </span>
                </div>
                <div className="wrap-input100 validate-input" required>
                  <input onChange={handleChange} className="input100" type="password" name="password" placeholder="Password" min="       " />
                  <span className="focus-input100" />
                  <span className="symbol-input100">
                    <i className="fa fa-lock" aria-hidden="true" />
                  </span>
                </div>
                <div className="container-login100-form-btn">
                  <input type="submit" className="py-2 px-4 flex justify-center items-center  bg-green-500 hover:bg-green-700 focus:ring-green-500 focus:ring-offset-green-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-full" value="Register" />
                   
                </div>
                
              </form>
            </div>
          </div>
        </div>
      );
}
export default Register;