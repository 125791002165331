import { Link} from 'react-router-dom'
import Logo from '../asset/images/img-01.png'
import Text from '../asset/images/text.png'
import React,{useContext,useEffect} from 'react'
import {AppContext} from "../context/AppContext"

const Navbar = () => {
  
   const {stage,handleFunction} = useContext(AppContext)
   const{image_url}=stage
   const{LogOut}=handleFunction
      return (
          <> 
           <div className="topnav">
              
        <img src={Logo} width={90} alt="Logo" />
        
        <form>
        <div className="dropdown">
  <button className="mainmenubtn">
	  <img src={image_url} width={30} className="rounded-full"/> </button>
  <div className="dropdown-child">
  
     <Link to="/Lowongan" className=" text-sm">Lowongan</Link> 
     <Link to="/Login" onClick={LogOut} className="text-sm"> 
  
     Log Out </Link>
  </div>
</div>
        
    
        </form>
      </div>
        
   </>     
      )
  }
  export default Navbar;