import { Link} from 'react-router-dom'
import Logo from '../asset/images/img-01.png'
import Text from '../asset/images/text.png'
import React,{useContext,useEffect} from 'react'
import Cookies from "js-cookie";


const Navbar = () => {
  
  
      return (
          <> 
         
          <div className="topnav">
        <img src={Logo} width={90} alt="Logo" />
        <img id="Text" src={Text} width={120} alt="Logo" />
        <Link id="NavLanding" to="/" className="text-xl font-bold">Beranda</Link>
        <Link id="NavLanding" to="/Lowongan" className="text-xl font-bold">Lowogan</Link>
        <form>
        {Cookies.get('token')&& <Link to="/AppList" className="text-xl font-bold">Dashboard</Link>}
        {!Cookies.get('token')&& <Link to="/Login" className="text-xl font-bold">Login</Link> }
        </form>
      </div>
   </>     
      )
  }
  export default Navbar;