import React from 'react';
import Navbar from './navbar';
import Sidebar from './sidebar'



const DashboardLayout = (props) => {
    return(
        <>
        
          
    <Navbar />
    <Sidebar/>  
    {props.children}

        
    
        </>
    )
}

export default DashboardLayout;