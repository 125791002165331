import React,{useContext} from 'react';
import {Link} from 'react-router-dom'
import {AppContext} from "../context/AppContext"
import '../asset/css/landingPage.css'
const LandingPage=()=>{
  const {stage,handleFunction} = useContext(AppContext)
  let {
    workList,setWorkList,
    status, setStatus, history,
    currentId, setCurrentId,
    input,setInput,
    inputLogin, setInputLogin
}=stage
  
let {handleSelect,handleChange,handleRegister }= handleFunction
    
    
    return (<>
            
     <div className="landing">
         <div className="container">
          <h1 >a Batter way to </h1>
          <h1> Search Work</h1>
        <br/>
       <Link id="Getstar" to="/Lowongan"> <button  type="button" value="Get Started" className='inline-block rounded-sm font-medium border border-solid cursor-pointer text-center text-lg py-3 px-8 text-white bg-blue-400 border-cyan-400 hover:bg-blue-600 hover:text-white' > get started </button>
     </Link>
      </div>
      <br/>
      </div>
    </>)
}
export default LandingPage;