import logo from './logo.svg';
import './asset/css/App.css';
import './asset/css/SignIn.css';
import Routes from './context/Routes';
function App() {
  return (
    <div className="App">
     <Routes/>
    </div>
  );
}

export default App;
