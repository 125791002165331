import React,{useContext} from "react"
import { Link } from "react-router-dom"
import axios from "axios"
import {AppContext} from "../context/AppContext"
import Cookies from "js-cookie";

const Profil = () =>{
  const {stage,handleFunction} = useContext(AppContext)
  let {
    workList,setWorkList,
    status, setStatus, history,
    currentId, setCurrentId,
    input,setInput,
    inputLogin, setInputLogin, thClass,tdClass,trClass,
    dataUser, setDataUser,pass,name,email,image_url
}=stage
  
let {handleSelect,handleChange,handleRegister,handleDelete,handleEdit,handleChangePass,ChangePassword }= handleFunction

return(
    <> {console.log(workList)}
    { workList !== null &&
      (<>{console.log(pass)}
      <br/>
           <div className="containerfom">
   <div className="md:flex gap-12">
        <div className="md:w-1/3 text-center mb-8 md:mb-2">
          <img className="w-48 h-48 rounded-full mx-auto -mb-24" src={image_url} alt="profil" />
          <div className="bg-white shadow-lg rounded-lg px-8 pt-32 pb-10 text-gray-400">
            <h3 className="font-title text-gray-800 text-xl mb-3">
              {email}
            </h3>
            <p className="font-body text-sm mb-4">
              {name}
            </p>
          </div>
        </div>
        <div className="md:w-1/2 text-center">
          <h1 className="font-bold text-3xl ">Change Password</h1>
          <div className="bg-white shadow-lg rounded-lg px-8 pt-10 pb-10 text-gray-400">
          <form method="post" onSubmit={ChangePassword} className='inputform' id="input">
 
 <label>
   Masukkan password Lama:
 </label>          
 <input type="password"  onChange={handleChangePass} name="current_password" required/>
 <label>
   Masukkan Password baru:
 </label>          
 <input type="password"  onChange={handleChangePass} name="new_password" required/>
 <label>
   konfirmasi Password baru:
 </label>          
 <input type="password"  onChange={handleChangePass} name="new_confirm_password" required/>


  <input type="submit" value="Submit"/>
  </form>
          </div>
        </div>
      </div>
  
      </div>


      
    </>
     )}
     
   </>  )

}

export default Profil