import React, { useContext,useEffect} from "react"
import {AppContext} from "../context/AppContext"
import { useParams } from "react-router-dom"
import axios from "axios"

const Appform = () => {
    let {id}= useParams()
    const {stage,handleFunction} = useContext(AppContext)
    let {
      workList,setWorkList,
      status, setStatus, history,
      currentId, setCurrentId,
      input,setInput,
      inputLogin, setInputLogin, 
      usertoken, setUsertoken
  }=stage
    
  let {handleSelect,handleChangeform,handleRegister,handleSubmit,page }= handleFunction

 
  useEffect(() => {
    if(id !== undefined) {
      axios.get(`https://dev-example.sanbercloud.com/api/job-vacancy/${id}`, {headers: {"Authorization" : "Bearer "+ usertoken}})
      .then(res => {
        let data = res.data
        setInput(data)
        setCurrentId(id)
      }
    )}
    return()=> {
      setInput({ 
        title:"",
        job_description:"",
        job_qualification :"",
        job_type:"" ,
        job_tenure:"" ,
        job_status:0,
        company_name:"" ,
        company_image_url:"" ,
        company_city:"" ,
        salary_min:0,
        salary_max:0  
})
      setCurrentId(-1)
    }
  },[])
    
  return(
    <> 
    <form id="InputFom" onSubmit={handleSubmit} method="post" className="flex w-full max-w-5lg space-x-3">
        <div className="w-full max-w-2xl px-5 py-10 m-auto mt-10 bg-white rounded-lg shadow dark:bg-gray-800">
          <div className="mb-6 text-3xl font-bold  text-center text-gray-800 dark:text-white">
            Form input Lowongan
          </div>
          <div className="grid max-w-xl grid-cols-2 gap-4 m-auto">
            <div className="col-span-2 lg:col-span-1">
              <div className=" relative ">
              <p className=" px-4 text-left text-xl text-indigo-500">title</p>
                <input type="text"  className=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" 
                onChange={handleChangeform} name="title" value={input.title} name="title" placeholder="title" />
              </div>
            </div>
            <div className="col-span-2 lg:col-span-1">
              <div className=" relative ">
              <p className="px-4 text-left text-xl text-indigo-500">Qualification</p>
                <input type="text"  className=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" 
                onChange={handleChangeform} value={input.job_qualification} name="job_qualification" placeholder="Qualification" />
              </div>
            </div>

            <div className="col-span-2 lg:col-span-1">
              <div className=" relative ">
              <p className="px-4 text-left text-xl text-indigo-500">Type</p>
                <input type="text"  className=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" 
                onChange={handleChangeform} value={input.job_type}name="job_type" placeholder="Job Type" />
              </div>
            </div>
            <div className="col-span-2 lg:col-span-1">
              <div className=" relative ">
              <p className="px-4 text-left text-xl text-indigo-500">Tenure</p>
                <input type="text"  className=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" 
                onChange={handleChangeform} value={input.job_tenure} name="job_tenure" placeholder="Tenure" />
              </div>
            </div>



            <div className="col-span-2 lg:col-span-1">
              <div className=" relative ">
              <p className="px-4 text-left text-xl text-indigo-500">Status</p>
                <input type="number" min="0" max="1"  className=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" 
               onChange={handleChangeform} value={input.job_status} name="job_status" placeholder="Status" />
              </div>
            </div>
            <div className="col-span-2 lg:col-span-1">
              <div className=" relative ">
              <p className="px-4 text-left text-xl text-indigo-500">Image Url</p>
                <input type="text" className=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" 
                onChange={handleChangeform} value={input.company_image_url} name="company_image_url" placeholder="Image url" />
              </div>
            </div>

            <div className="col-span-2 lg:col-span-1">
              <div className=" relative ">
              <p className="px-4 text-left text-xl text-indigo-500">Company Name</p>
                <input type="text"  className=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" 
                onChange={handleChangeform} value={input.company_name} name="company_name" required placeholder="Company Name" />
              </div>
            </div>
            <div className="col-span-2 lg:col-span-1">
              <div className=" relative ">
              <p className="px-4 text-left text-xl text-indigo-500">Company City</p>
                <input type="text"  className=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" 
                onChange={handleChangeform} value={input.company_city} name="company_city" required placeholder="Company City" />
              </div>
            </div>

            <div className="col-span-2 lg:col-span-1">
              <div className=" relative ">
              <p className="px-4 text-left text-xl text-indigo-500">Salary min</p>
                <input type="number" min="1000"  className=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" 
                onChange={handleChangeform} value={input.salary_min} name="salary_min"required placeholder="Salary min" />
              </div>
            </div>
            <div className="col-span-2 lg:col-span-1">
              <div className=" relative ">
              <p className="px-4 text-left text-xl text-indigo-500">Salary Max</p>
                <input type="number" min="1000" className=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" 
                onChange={handleChangeform} value={input.salary_max} name="salary_max"required placeholder="Salary Max" />
              </div>
            </div>

            <div className="col-span-2">
              <label className="text-gray-700" htmlFor="name">
              <p className="px-4 text-left text-xl text-indigo-500">Job Description</p>
                <textarea onChange={handleChangeform} value={input.job_description} required className="flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 rounded-lg text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" id="comment" placeholder="Enter your comment" name="job_description"  rows={5} cols={40} />
              </label>
            </div>
            <div className="col-span-2 text-right">
              <button type="submit" className="py-2 px-4  bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>



     
      </>
    )
}

export default Appform 