import React, {useContext,useState,useEffect} from "react"
import {Link,useHistory} from 'react-router-dom'
import axios from "axios"
import {AppContext} from "../context/AppContext"
import Cookies from "js-cookie";
const FilterSEction=()=>{
  const {stage,handleFunction} = useContext(AppContext)
  let {
    workList,setWorkList,
    status, setStatus, history,
    currentId, setCurrentId,
    input,setInput,
    inputLogin, setInputLogin,search,filterdata,newdata,setNewdata
}=stage
  
let {handleSelect,handleChange,handleRegister,JobType,handleChangeSearch,handleSearch,handleChangeFilter,handleFilter,back }= handleFunction
  
  return (<>
    { newdata !== null &&
      ( 
        <div className="flex-1 lg:pl-12 py-6 px-6 lg:px-0">{console.log(newdata)}
          <form method="post" onSubmit={handleSearch} className="text-right px-6" >
          <input onClick={back} onChange={handleChangeSearch} className="border border-indigo-500 text-black bg-indigo-100 rounded-full mx-2 py-2" type="text" name="search" placeholder="Cari..." required/>	
      <input value="Search"type="submit" className="px-4 py-2  text-base rounded-full text-white  bg-indigo-500" />
      </form>
      
      <form method="post" onSubmit={handleFilter} className="text-left px-6" >
      
      <br/>
     

        <input onClick={back}  onChange={handleChangeFilter} className="border border-indigo-500 text-black bg-indigo-100 rounded-full mx-2 my-4 py-2" type="text" name="company_city" placeholder="City" />	
       
       
    <input value="Filter"type="submit" className="px-4 py-2  text-base rounded-full text-white  bg-indigo-500" />
    </form>
    
      

        <div className="mt-12">
          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-6 mt-6">
          
             {
        newdata.map((item, index)=>{
    return( <>
      
 		

      <div   className="bg-white dark:bg-gray-800 w-72 shadow-lg mx-auto rounded-xl p-4" key={index}>
      <img src={item.company_image_url} width="100"/> 
      <p className="font-bold text-xl text-blue-600">{item.title}</p>
      <p className="font-bold text-xl text-left ">{item.company_name}</p>
      <br/>
      <p className="flex font-bold text-xl text-left text-black ">{item.company_city}</p>
      <div className="flex text-left font-bold">
      IDR Rp.{item.salary_min}-Rp.{item.salary_max}
        </div><br/>

  
       <table className="text-gray-600 dark:text-white text-left">
         <tbody key={item.id}>
         <tr>{console.log(item.id)}
           <td>Tenur </td>
           <td>: {item.job_tenure}</td>
        </tr> 
        <tr>
           <td>Type </td>
           <td>: {item.job_type} </td>
        </tr> 
        <tr>
           <td>Status </td>
           <td>: {JobType(item.job_type)}</td>
        </tr> 
        </tbody>
       </table>
      
      
        <button type="submit" onClick={handleSelect}  value={item.id}  className="px-4 py-2 text-base  rounded-full text-indigo-500 bg-indigo-200 border border-indigo-500  hover:bg-indigo-500 hover:text-white undefined " >Detail</button>

      </div>
   

      </> )
            
})}
</div>
</div> 
</div>)

}
</>)
}
export default FilterSEction;