import React from "react";
import  gmail from '../asset/images/gmail.png'
import  ig from '../asset/images/ig.png'
import  wa from '../asset/images/wa.png'
import '../asset/css/footer.css'
import { Link} from 'react-router-dom'
const Footer  = () => {
    return(<>
    <footer>
    <h5>&copy; Muhammad Aji Massaid</h5>
    <div className="box">
     
        
        <div className="left">
        <Link to="https://wa.me/628811659249" className="contact-icon-bundler">
            <img id="Foto" src={wa} alt="phone.png" className="contact-icon" />
            <span id="Text" className="contact-desktop">08811659249</span>
          </Link>
        </div>
                <div className="center">
                <Link to="mailto:amassaid99@gmail.com" className="contact-icon-bundler">
            <img id="Foto" src={gmail} alt="mail.png" className="contact-icon" />
            <span id="Text" className="contact-desktop">amassaid@gmail.com</span>
          </Link>
                </div>
                <div className="right">
                <Link to="https://www.instagram.com/rmassaid99" className="contact-icon-bundler">
            <img id="Foto" src={ig} alt="in.png" className="contact-icon" />
            <span id="Text" className="contact-desktop">@rmassaid99</span>
          </Link>
                </div>
      </div>


       
    </footer>
    </>)
}
export default Footer;