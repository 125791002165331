import React,{useContext} from "react"
import { Link } from "react-router-dom"
import axios from "axios"
import {AppContext} from "../context/AppContext"
import '../asset/css/Applist.css'


const AppList = () =>{
  const {stage,handleFunction} = useContext(AppContext)
  let {
    workList,setWorkList,
    status, setStatus, history,
    currentId, setCurrentId,
    input,setInput,
    inputLogin, setInputLogin, thClass,tdClass,trClass,name,token
}=stage
  

let {handleSelect,handleChange,handleRegister,handleDelete,handleEdit }= handleFunction

return(
    <> 
    { workList !== null &&
      (
      <div className="containerfom">
      <br/><div className="w-full px-6 py-3 rounded-sm border text-gray-800 bg-gray-200 border-gray-300" role="alert">
<p className="text-xl font-bold text-indigo-400 mb-4">SELAMAT DATANG {name}</p>
<p className="text-md font-bold text-indigo-400 mb-4">Semangat kerja, ingat ada orang Tua yang harus kamu Banggakan</p>
      </div><br/>
       
      <div id="tabelList" className="table p-2 bg-white shadow rounded-lg" >
      <Link to={'/Appform'} className="px-4 py-2  text-bold rounded-full text-indigo-500 border border-indigo-500 hover:bg-indigo-500 hover:text-white" id="left-List">  <button > Buat Data Baru</button></Link>
      
          <h1 className="text-center text-3xl font-bold">Daftar Work List</h1>
        <br/><br/>
        <table >
          <thead>
            <tr>
              <th className={thClass}>No</th>
              <th className={thClass}>Title</th>
              <th className={thClass}>Job Description</th>
              <th className={thClass}>Qualification</th>
              <th className={thClass}>Job Type</th>
              <th className={thClass}>Tenure</th>
              <th className={thClass}>Job Status</th>
              <th className={thClass}>Company Name</th>
              <th className={thClass}>Company Image</th>
              <th className={thClass}>City</th>
              <th className={thClass}>Salary min</th>
              <th className={thClass}>Salary Max</th>
              <th className={thClass}>Action</th>
            </tr>
          </thead>
          <tbody>
          {
                workList.map((item,index)=>{
                 
                 
                  return(                    
                    <tr className={trClass} key={index}>
                      <td className={tdClass}>{index}</td>
                      <td className={tdClass}>{item.title}</td>
                      <td className={tdClass}>{item.job_description}</td>
                      <td className={tdClass}>{item.job_qualification}</td>
                      <td className={tdClass}>{item.job_type}</td>
                      <td className={tdClass}>{item.job_tenure}</td>
                      <td className={tdClass}>{item.job_status}</td>
                      <td className={tdClass}>{item.company_name}</td>
                      <td className={tdClass}><img src={item.company_image_url} width="80"/></td>
                      <td className={tdClass}>{item.company_city}</td>
                      <td className={tdClass}>{item.salary_min}</td>
                      <td className={tdClass}>{item.salary_max}</td>
                      <td className={tdClass}>
                          <button className="px-4 py-2  text-base rounded-full text-white  bg-indigo-500 my-4 hover:bg-indigo-200" onClick={handleEdit}  value={item.id}>Edit</button> &nbsp;
                          <button className="px-4 py-2  text-base rounded-full text-red-600 border border-red-600 bg-red-200 hover:bg-red-500 hover:text-white" onClick={handleDelete}  value={item.id}>Delete</button>
                      </td>
                    </tr>
                  )
                })
              }
          </tbody>

        </table>
        </div>
      </div>        
      )}
      </>
  )

}

export default AppList