import React, {useContext,useState,useEffect} from "react"
import {Link} from 'react-router-dom'
import axios from "axios"
import { useParams } from "react-router-dom"
import {AppContext} from "../context/AppContext"
const LowonganDetail=()=>{
  const {stage,handleFunction} = useContext(AppContext)
  let {
    newdata,setNewdata,
    status, setStatus, history,
    currentId, setCurrentId,
    input,setInput,
    inputLogin, setInputLogin,thClass
}=stage
  
let {handleSelect,handleChange,handleRegister,JobType,BackLowongan }= handleFunction

    
        let {slug} = useParams()
    
         
    
            useEffect(() => {
                if(slug !== undefined) {
                  axios.get(`https://dev-example.sanbercloud.com/api/job-vacancy/${slug}`)
                  .then(res => {

                    const data = res.data 
                    setCurrentId(slug)
                     setNewdata(data)
                     console.log(newdata)
                    
               
                  }
                )}
                return()=> {
                  setInput({ id:0,
                    title:"",
                    job_description:"",
                    job_qualification :"",
                    job_type:"" ,
                    job_tenure:"" ,
                    job_status:0,
                    company_name:"" ,
                    company_image_url:"" ,
                    company_city:"" ,
                    salary_min:0,
                    salary_max:0  })
                  
                    setStatus(true) 
                }
              },[status, setStatus]) 
    return (<>
    { slug !== undefined &&
      (
        <div id="LowonganDetail" className="flex-1 lg:pl-12 py-6 px-6 lg:px-0">
        <div className="mt-12">
        <section className="h-screen bg-gray-100 bg-opacity-50">
        <form className="container max-w-7xl mx-auto shadow-md md:w-1/20">
          <div className="p-4 bg-gray-100 border-t-2 border-indigo-400 rounded-lg bg-opacity-5">
            <div className="max-w-sm  ">
              <div className="flex items-center space-x-4">
                
                  <img alt="profil" src={newdata.company_image_url} className="mx-auto object-cover  h-16 w-16 " />
              
                
                <h1 className="text-3xl text-gray-600">
                  {newdata.title}
                </h1>
                
              </div>
            </div>
          </div>
          <div className="space-y-6 bg-white md:mx:0">
          <div>
            <h1 className="text-left text-3xl font-bold text-indigo-800">{newdata.company_name}</h1>
            <table>
              <tbody>
                <tr>
                  <td className={thClass}>job Type </td>
                  <td className={thClass}>{newdata.job_type}</td>
                </tr>
                <tr>
                  <td className={thClass}>job tenure </td>
                  <td className={thClass}>{newdata.job_tenure}</td>
                </tr>
                <tr>
                  <td className={thClass}>job Status </td>
                  <td className={thClass}>{JobType(newdata.job_type)}</td>
                </tr>
                <tr>
                  <td className={thClass}>qualification </td>
                  <td className={thClass}>{newdata.job_qualification}</td>
                </tr>
                <tr>
                  <td className={thClass}>description</td> 
                  <td className={thClass}>{newdata.job_description}</td>
                </tr>
                <tr>
                  <td className={thClass}>Company City</td> 
                  <td className={thClass}>{newdata.company_city}</td>
                </tr>
                
          </tbody>
          </table>
          <br/>
          <div className="w-full px-4 pb-4 ml-auto text-gray-500 md:w-1/3">
        
        <Link to="/Lowongan"   type="submit" className="py-2 px-4  bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-blue-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">
          Back
        </Link>
      </div>
          </div>
           
          </div>
        </form>
      </section>
</div> 
</div>)

}
</>)
}
export default LowonganDetail;